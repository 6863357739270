<template>
  <!-- 项目管理 -->
  <div class="page-wrap">
    <div class="nav flex items-center px-2.5 py-2 bg-white" style="padding-right:0">
      <div class="screen-wrap">
        <div class="flex-1 van-ellipsis" @click="screenChange(0)">{{ screen_region.areaName }}</div>
        <van-icon name="arrow-down" color="#666" size="12" />
      </div>
      <div class="screen-wrap">
        <div class="flex-1 van-ellipsis" @click="screenChange(1)">{{ screen_city.cityName }}</div>
        <van-icon name="arrow-down" color="#666" size="12" />
      </div>
    </div>

    <List
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      class="px-2 5 py-3 5"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index" class="mb-2 px-3 py-4 divide-y rounded bg-white">

        <div>
          <div class="flex items-center pb-3">
            <div class="flex-1 text-sm font-medium ">【{{item.areaName}}】【{{item.cityName}}】{{ item.projectName }}</div>
            <div class="btn text-sm px-2 rounded border border-blue-400 text-blue-400 " @click="editProject(item)">编辑</div>
            <div class="ml-5 text-sm underline text-gray-400" v-show="authority=='true'" @click="delClick(item,index)">删除</div>
          </div>

          <div class="flex items-center pt-3">
            <div class="flex items-center mr-10">
              <div class="porject-info-icon">
                <img :src="require('assets/project/people.png')" alt="">
              </div>
              <div class="project-info-name ml-2 text-sm text-blue-400" @click="toPeople(item)">人员信息</div>
            </div>
            <!-- <div class="flex items-center mr-10">
                <van-icon name="manager" color="rgba(59, 130, 246, 0.5)" />
              <div class="project-info-name ml-2 text-sm text-blue-400" @click="toGroup(item)">集团人员</div>
            </div> -->
            <div class="flex items-center mr-10">
              <div class="porject-info-icon">
                <img :src="require('assets/project/supplier.png')" alt="">
              </div>
              <div class="project-info-name ml-2 text-sm text-blue-400" @click="toSupplier(item)">供方信息</div>
            </div>
          </div>
        </div>
      </div>
    </List>
    <div class="kong"></div>
    <div class="btn-box">
      <div class="btn" @click="$router.push({ path:'/regulate/project/add' })">新增</div>
    </div>
    <van-popup v-model="pop_picker" position="bottom">
      <van-picker
        :default-index="picker_active"
        show-toolbar
        :columns="picker_list"
        :value-key="screen_type==0?'areaName':'cityName'"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>
  </div>
</template>
<script>
import { Icon, Popup, Picker, List } from 'vant'
import { areaList, cityList, projectList, projectDel } from '../../../api'
export default {
  name:'ProjectList',
  components:{
    [Icon.name]:Icon,
    [Popup.name]:Popup,
    [Picker.name]:Picker,
    List
  },
  data() {
    return {
      screen_region:{ areaName:'区域', id:'' },
      screen_city:{ cityName:'城市', id:'' },
      screen_type:-1,

      pop_picker:false,
      picker_list:[],
      picker_active:0,
      // 城市列表
      city_list:[{ id:'', cityName:'城市' }],
      // 区域列表
      region_list:[{ id:'', areaName:'区域' }],
      // 当前筛选的类型
      pop_screen_type:'',
      list:[],
      loading:false,
      finished:false,
      current:1,
      authority: 'false'
    }
  },
  created() {
    this.authority = localStorage.getItem('authority')
    this.areaList()
  },
  methods:{
    onLoad() {
      this.projectList()
    },
    onConfirm(value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`)
      this.list = []
      this.current = 1
      // this.status_active = value
      if (this.screen_type == 0) {
        this.screen_region = { areaName:value.areaName, id:value.id }
        this.city_list=[{ id:'', cityName:'城市' }]
        this.cityList(value.id)
      } else if (this.screen_type == 1) {
        this.screen_city = { cityName:value.cityName, id:value.id }
      }
      this.projectList()
      this.pop_picker = false
    },
    onChange(picker, value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`)
    },
    onCancel() {
      console.log('取消')
      this.pop_picker = false
    },
    screenChange(screen_type) {
      // screen_type 0区域 1城市
      let screen_list = []
      let screen_active = 0
      if (screen_type == 0) {
        screen_list = this.region_list
        screen_active = this.screen_region.index
      } else if (screen_type == 1) {
        screen_list = this.city_list
        screen_active = this.screen_city.index
      }
      this.screen_type = screen_type
      this.picker_list = screen_list
      this.picker_active = screen_active
      this.pop_picker = true

      // console.log('screenChange112', screen_list, screen_active)
    },
    editProject(item) {
      // console.log('editProject', item)
      this.$router.push({ name:'projectDetail', query: {id: item.id} })
    },
    toPeople(item) {
      this.$router.push({ name:'people',query: {id: item.id} })
    },
    toSupplier(item) {
      this.$router.push({ name:'supplier',query: {id: item.id} })
    },
    toGroup(item){
      this.$router.push({ name:'group',query: {id: item.id} })
    },
    // 区域列表
    areaList() {
      areaList({}).then(res => {
        this.region_list = this.region_list.concat(res.data.data)
      })
    },
    // 城市列表
    cityList(id) {
      if (id) {
        cityList({
          areaId:this.screen_region.id
        }).then(res => {
          this.city_list = this.city_list.concat(res.data.data)
        })
      }
    },
    // 项目列表
    projectList() {
      this.$toast.loading({
        forbidClick:true,
        duration:0
      })
      projectList({
        areaId:this.screen_region.id,
        cityId:this.screen_city.id,
        projectName:'',
        current:this.current,
        size:20,
        type:0
      }).then(res => {
        this.current++
        this.loading = false
        var list = res.data.data.records
        if (list.length == 0) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.list = this.list.concat(list)
      }).finally(res => {
        this.$toast.clear()
      })
    },
    //删除projectDel
    delClick(item,index){
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除该项目',
      })
        .then(() => {
          // on confirm
          this.$toast.loading({
              message: '',
              forbidClick: true,
              duration: 0
          });
          projectDel(item.id).then((res)=>{
            this.$toast.success('删除成功');
            this.list.splice(index, 1)
          }).catch(()=>{
            this.$toast.clear()
          })
        })
    }
  }
}
</script>
<style scoped>
  .porject-info-icon{
    width:20px;
    height:20px;

  }
  .porject-info-icon img{
    width:100%;
    height:100%;
  }
    .page-wrap{
    position: relative;
    background-color: #f6f6f6;
    min-height:100vh;
  }
  .btn-box{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
  }
  .btn-box .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 339px;
    height: 42px;
    background: linear-gradient(-42deg, #7FA9FF, #6497FF);
    border: 1px solid #6A99F9;
    border-radius: 5px;
    box-shadow: 0.125rem 0.1875rem 0.6875rem 0 rgb(255 255 255 / 26%) inset, -0.0625rem -0.0625rem 0.5625rem 0 rgb(90 140 243 / 67%) inset;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
  }
  .kong{
    width: 100%;
    height: 55px;
  }
</style>
